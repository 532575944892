<!-- Copyright © 2021 All rights reserved. -->

<!--
 * A single Message component.
 *
 * @author Brandon Li <brandon.li@berkeley.edu>
!*-->

<template>
  <div>
    <div v-if='incoming' class='incoming_msg'>
        <div class="received_msg">
          <div class="received_withd_msg">
            <span class='user'> {{ user }} </span>
            <p> {{ text }} </p>
            <span class="time_date"> {{ date }} </span></div>
        </div>
    </div>

    <div v-else class="outgoing_msg">
      <div class="sent_msg">
        <span class='user'> {{ user }} </span>
          <p> {{ text }} </p>
          <span class="time_date"> {{ date }} </span></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    incoming: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    user: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
</style>