<template>
  <div class='main'>
    <div class="mesgs">
      <div class="msg_history" ref='history'>
        <Message
          v-for='(message, index) in messages'
          :key='index'
          :incoming='message.incoming'
          :date='message.date'
          :text='message.text'
          :user='message.user'
        />
      </div>
      <div class="type_msg">
        <div class="input_msg_write">
          <input ref='input' type="text" class="write_msg" placeholder="Type a message" @keydown.enter='send'/>
          <button class="msg_send_btn" type="button" @click='send'>send</button>
        </div>
      </div>
    </div>
    <div class='a'> <img src='@/assets/img.jpg'>  <p> Hiii {{ name }} </p> </div>

  </div>
</template>

<script>
import axios from 'axios';
import io from "socket.io-client";
import Message from '@/components/Message';

const steph = 'skwan'
const brandon = 'brandon 💕'

function isIncoming(isBrandon, user) {
  if (isBrandon) {
    return user == steph;
  }
  else {
    return user != steph;
  }
}

export default {
  data() {
    return {
      isBrandon: false,
      messages: [],
      name: ''
    }
  },
  components: {
    Message
  },
  async mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.isBrandon = urlParams.has('m');
    console.log(this.isBrandon)
    this.name = this.isBrandon ? 'brandon' : 'stephanie 💕'
    const socket = io("https://stephaniebrandonwlicom.herokuapp.com");
    window.socket = socket;

    this.$nextTick(async () => {
      await this.updateMessages();
    });

    socket.on('read-msg', data => {
      this.messages.push({
        ...data,
        incoming: isIncoming(this.isBrandon, data.user)
      });
      this.$nextTick(() => this.$refs.history.scrollTop = this.$refs.history.scrollHeight);
    });
  },
  methods: {
    async updateMessages() {
      const response = await axios.get('https://stephaniebrandonwlicom.herokuapp.com/get');
      const messages = response.data.messages;

      this.messages = messages.map(data => {
        return {
          ...data,
          incoming: isIncoming(this.isBrandon, data.user)
        }
      });
      this.$nextTick(() => this.$refs.history.scrollTop = this.$refs.history.scrollHeight);
    },
    async send(message) {
      if (!message.target.value) {
        return;
      }
      socket.emit('send-msg', { message: message.target.value, user: this.isBrandon ? brandon : steph });
      this.$refs.input.value = ''
    }
  }
};
</script>

<style scoped>

  .main {
    display: flex;
  }

@media only screen and (max-width: 600px) {
  .main {
    flex-direction: column;
    align-items: center;
  }
}


img {
  width:  200px;
  height:  auto;
  display: block;
  margin-left:  100px;
  margin-top:  50px;
}

@media only screen and (max-width: 600px) {
  img {
  width:  200px;
  height:  auto;
  display: block;
  margin-left:  0
}
}

.a {
  display:  flex;
  flex-direction: column;
  align-items: center;
}

.a img {
  align-self:  center;
  display:  block;
}

.a p {
  text-align: center;
  align-self:  center;
  display:  block;
width:  200px;
}
</style>